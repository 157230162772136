import LogoHeader from "./../assets/images/icons/logo1.png";
import LogoShort from "./../assets/images/icons/logo2.png";
import Documenticon from "./../assets/images/icons/folder.png";
import Users from "./../assets/images/icons/users-alt.png";
import Bank from "./../assets/images/icons/bank-check.png";
import Gamepad from "./../assets/images/icons/gamepad.png";
import Money from "./../assets/images/icons/money.png";
import Money1 from "./../assets/images/icons/money (1).png";
import Rupee from "./../assets/images/icons/rupee.png";
import Pupees from "./../assets/images/icons/rupees.png";
import Calendar from "./../assets/images/icons/calendar.png";
import Trophy from "./../assets/images/icons/trophy.png";

const IMAGES = {
  LogoHeader: LogoHeader,
  LogoShort: LogoShort,
  Documenticon: Documenticon,
  Users: Users,
  Bank: Bank,
  Gamepad: Gamepad,
  Money: Money,
  Rupee: Rupee,
  Pupees: Pupees,
  Calendar: Calendar,
  Trophy: Trophy,
  Money1: Money1,
};
export default IMAGES;
