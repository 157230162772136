// ShowPfESiHistory.js

import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


function ShowPfESiHistory() {
    return (
        <div className="container-fluid">
            {/* start page title */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Show Payment  Details</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="''">Payment </a></li>
                                <li className="breadcrumb-item active">Show Payment details </li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* end page title */}

            <div className="row">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap">
                            <thead>
                                <tr>
                                    <th>Department Type</th>
                                    <th>Labour Name</th>
                                    <th>Amount type</th>
                                    <th>Enter Type</th>
                                    <th>Till date Amount</th>
                                    <th>Why taken Amount</th>
                                </tr>
                            </thead>
                            {/* Your table body goes here */}
                        </table>
                    </div>
                 
                </div>
            </div>
            {/* end row */}
        </div>
    );
}

export default ShowPfESiHistory;
