import React, { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
  FileOutlined,
  ApartmentOutlined,
  EyeOutlined,
  LayoutOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";

const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();


  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />


      <div className="sidebar_menus" style={{background:'#2A3042', height: "100%"}}>
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#2A3042" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard",
              onClick: () => {
                navigate("/");
              },
            },


            {
              key: "deparment",
              icon: <LayoutOutlined />,
              label: "Create Department",

              children: [
                {
                  key: "createdepart",
                  icon: <LayoutOutlined />,
                  label: "Create Department",
                  onClick: () => {
                    navigate("/createdepartment");
                  },
                },

                {
                  key: "seedepart",
                  icon: <EyeOutlined />,
                  label: "Show Department",
                  onClick: () => {
                    navigate("/showdepartment");
                  },
                },
              ],
            },

            {
              key: "deparmentrate",
              icon: <LayoutOutlined />,
              label: "Department Rate",

              children: [
                {
                  key: "createdepartrate",
                  icon: <LayoutOutlined />,
                  label: "Create Department Rate",
                  onClick: () => {
                    navigate("/createdepartmentrate");
                  },
                },

                {
                  key: "seedepartrate",
                  icon: <EyeOutlined />,
                  label: "Show Department Rate",
                  onClick: () => {
                    navigate("/showdepartmentrate");
                  },
                },
              ],
            },

            {
              key: "labour",
              icon: <LayoutOutlined />,
              label: "Labour Registration",

              children: [
                {
                  key: "labourregistration",
                  icon: <LayoutOutlined />,
                  label: "Labour Registration",
                  onClick: () => {
                    navigate("/labourregistration");
                  },
                },

                {
                  key: "seelabour",
                  icon: <EyeOutlined />,
                  label: "Show Labour",
                  onClick: () => {
                    navigate("/showlabour");
                  },
                },
              ],
            },

            
            {
              key: "Attendence",
              icon: <LayoutOutlined />,
              label: "Attendence",

              children: [
                {
                  key: "labourAttendence",
                  icon: <LayoutOutlined />,
                  label: "Labour Attendence",
                  onClick: () => {
                    navigate("/labourAttendence");
                  },
                },

                {
                  key: "seelabourAttendence",
                  icon: <EyeOutlined />,
                  label: "Show Attendence",
                  onClick: () => {
                    navigate("/showLabourAttendence");
                  },
                },
              ],
            },

            {
              key: "billmanagement",
              icon: <LayoutOutlined />,
              label: "Bill Management",

              children: [
                {
                  key: "crbill",
                  icon: <LayoutOutlined />,
                  label: "Create Bill ",
                  onClick: () => {
                    navigate("/createbill");
                  },
                },

                {
                  key: "seebills",
                  icon: <EyeOutlined />,
                  label: "Show Bills",
                  onClick: () => {
                    navigate("/showbills");
                  },
                },
              ],
            },

            // Monthly Payment List

            {
              key: "monthlypaymentlist",
              icon: <LayoutOutlined />,
              label: "Monthly Payment List",

              children: [
                {
                  key: "pymtHist",
                  icon: <LayoutOutlined />,
                  label: "Monthly  Payment History",
                  onClick: () => {
                    navigate("/monthly-pmt-hst");
                  },
                },
              ],
            },

            {
              key: "PaymentManagement",
              icon: <LayoutOutlined />,
              label: "Payment Management",

              children: [
                {
                  key: "PaymentHistory",
                  icon: <LayoutOutlined />,
                  label: "Payment History",
                  onClick: () => {
                    navigate("/PaymentHistory");
                  },
                },

                {
                  key: "ShowPaymentHistory",
                  icon: <EyeOutlined />,
                  label: "Show PaymentHistory",
                  onClick: () => {
                    navigate("/ShowPaymentHistory");
                  },
                },
              ],
            },


            {
              key: "PfESIManagement",
              icon: <LayoutOutlined />,
              label: "Pf ESI Management",

              children: [
                {
                  key: "CreatePfESi ",
                  icon: <LayoutOutlined />,
                  label: "Create Pf ESi",
                  onClick: () => {
                    navigate("/CreatePfESi");
                  },
                },

                {
                  key: "ShowPfESiHistory",
                  icon: <EyeOutlined />,
                  label: "Show Pf ESi History",
                  onClick: () => {
                    navigate("/ShowPfESiHistory");
                  },
                },
              ],
            },

          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
