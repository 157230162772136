import React, { useEffect, useRef } from 'react';

import { useFormContext } from '../../ContextApi/FormContext'
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import converter from 'number-to-words'


const styles = {
  a4Div: {
    width: 'fit-content',
    height: '35cm',
    margin: '0 auto',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1), 0px -5px 10px rgba(0, 0, 0, 0.1)',
    padding: '0.5cm 2cm',
  },
  a4Image: {
    display: 'block',
  },
  table: {
    width: '100%',
    marginTop: '10px',
    border: '2px solid black',
    borderCollapse: 'collapse',
  },
  thead: {
    border: '2px solid black',
    padding: '4px'
  },
  thTr: {
    border: '2px solid black',
    padding: '4px',
  },
  td: {
    borderLeft: '2px solid #000',
    borderRight: '2px solid #000',
    // padding: '4px',
  },
  borderTop: {
    borderTop: '2px solid #ffffff',
    padding: '4px',
  },
  center: {
    textAlign: 'center',
  }
};

const GenerateBill = () => {
  const { bill } = useFormContext()
  const nav = useNavigate()


  useEffect(() => {
    if (!bill) {
      nav('/showbills')
    }
  })


  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <div>
      <button onClick={handlePrint} title='Print this out!'><i className="fa-solid fa-print"></i></button>

      <div style={styles.a4Div} ref={componentRef} >
        <img src="./img.png" alt="Your Image Description" style={styles.a4Image} />

        <table style={styles.table}>
          <thead style={styles.thead}>
            <tr style={styles.thead}>
              <td colSpan="3">Invoice No. {bill?.invoiceNumber}</td>
              <td colSpan="4" style={{ textAlign: 'end' }}>Date: {new Date(bill?.billDate).toLocaleDateString('en-GB')}</td>
            </tr>
            <tr style={styles.thead}>
              <td colSpan="3" rowSpan="3">
                TO, SHRIRAM RAYONS <br />
                (A DIVISION DCM SHRIRAM INDUTRIES.) <br />
                GST REGN NO. 08AAACD024CIZL <br />
              </td>
            </tr>
            <tr style={styles.thead}>
              <td colSpan="2" style={{ ...styles.center, ...styles.td }}>PLANT</td>
              <td colSpan="2" style={styles.center}>{bill.departmentType.split(',')[1]}</td>
            </tr>
            <tr style={styles.thead}>
              <td colSpan="2" style={{ ...styles.center, ...styles.td }}>DURATION</td>
              <td colSpan="2" style={styles.center}>{new Date(bill?.startDate).toLocaleDateString('en-GB')} ΤΟ {new Date(bill?.endDate).toLocaleDateString('en-GB')}</td>
            </tr>
            <tr style={styles.thead}>
              <th style={{ fontSize: '13px', fontWeight: '900', textAlign: 'left' }}>Order No.</th>
              <th style={{ fontSize: '13px', fontWeight: '900', textAlign: 'left' }} colSpan="2">{bill.poNumber}</th>
              <td style={{ ...styles.center, ...styles.td, textAlign: 'center', paddingLeft: '5px' }} rowSpan="2">QTY</td>
              <td style={{ ...styles.center, ...styles.td }} rowSpan="2">UNIT</td>
              <td style={{ ...styles.center, ...styles.td }} rowSpan="2">RATE</td>
              <td style={{ ...styles.center, ...styles.td }} rowSpan="2">AMOUNT</td>
            </tr>
            <tr style={styles.thead}>
              <td style={{ fontSize: '13px' }}>ITEM NO.</td>
              <td style={{ ...styles.center, fontSize: '13px' }}>PARTICULAR'S</td>
            </tr>
          </thead>
          <tbody>

            {
              bill?.poDescription.split(/<\/p>|<\/h[1-6]>|<\/div>/)
                .map(item => item.trim() +
                  (item.includes('<h1>') ?
                    '</h1>' : item.includes('<h2>') ? '</h2>' : item.includes('<h3>') ? '</h3>' :
                      item.includes('<h4>') ? '</h4>' : item.includes('<h5>') ? '</h5>' : item.includes('<h6>') ?
                        '</h6>' : item.includes('<p>') ? '</p>' : item.includes('<div>') ? '</div>' : ''))
                .filter(Boolean).map((item, index) => {
                  console.log(item);
                  if (index === 0) return (

                    <tr key={index}>
                      <td style={{width: '30px', textAlign: 'center'}}>{index + 1}</td>
                      <td style={styles.td} colSpan="2" dangerouslySetInnerHTML={{ __html: item }} />
                      <td style={{ ...styles.center, ...styles.td }}>{bill?.quantity}</td>
                      <td style={{ ...styles.center, ...styles.td }}>{bill?.unit}</td>
                      <td style={{ ...styles.center, ...styles.td }}>{bill?.rate}</td>
                      <td style={{ ...styles.center, ...styles.td }}>{bill?.amount}</td>
                    </tr>
                  )

                  return (


                    <tr key={index}>
                      <td style={{width: '30px', textAlign: 'center'}}>{index + 1}</td>
                      <td style={styles.td} colSpan="2" dangerouslySetInnerHTML={{ __html: item }} />
                      <td style={{ ...styles.center, ...styles.td }}></td>
                      <td style={{ ...styles.center, ...styles.td }}></td>
                      <td style={{ ...styles.center, ...styles.td }}></td>
                      <td style={{ ...styles.center, ...styles.td }}></td>
                    </tr>
                  )

                })

            }


            <tr style={{ height: `${400-(bill?.poDescription.split(/<\/p>|<\/h[1-6]>|<\/div>/).length)*10 }px`}}>
              <td style={styles.td}></td>
              <td style={styles.td} colSpan="2"></td>
              <td style={{ ...styles.center, ...styles.td }}></td>
              <td style={{ ...styles.center, ...styles.td }}></td>
              <td style={{ ...styles.center, ...styles.td }}></td>
              <td style={{ ...styles.center, ...styles.td }}></td>
            </tr>

            <tr>
              <td style={styles.td}></td>
              <td style={{ ...styles.td, textAlign: 'end' }} colSpan="2">BASIC PRICE <br /> C GST% <br /> S GST% <br /></td>
              <td style={styles.center}>9.00 <br /> 9.00 <br /></td>
              <td style={{ ...styles.center, ...styles.td }}></td>
              <td style={{ ...styles.center, ...styles.td }}></td>
              <td style={styles.center}>{bill.amount} <br /> {bill.amount * .09} <br /> {bill.amount * .09}<br /></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td style={{ ...styles.td, ...styles.borderTop }}></td>
              <td style={{ ...styles.td, ...styles.borderTop }} colSpan="2"></td>
              <td style={{ ...styles.td, ...styles.borderTop }}></td>
              <td colSpan="2" style={{ textAlign: 'center', ...styles.thead }}>TOTAL</td>
              <td style={{ ...styles.center, ...styles.thead }}>{bill.amount + 2 * bill.amount * .09}</td>
            </tr>
            <tr>
              <td style={{ ...styles.center, ...styles.thead }} colSpan="4" rowSpan="3">
                INR: {converter.toWords(Math.round(bill.amount + 2 * bill.amount * .09)).toUpperCase()}  ONLY
              </td>
            </tr>
            <tr>
              <td style={{ ...styles.center, ...styles.td, ...styles.thTr }} colSpan="2">ROUND OF</td>
              <td style={{ ...styles.center, ...styles.td, ...styles.thTr }}>{Math.round(bill.amount + 2 * bill.amount * .09)}</td>
            </tr>
            <tr>
              <td style={{ ...styles.center, ...styles.td, ...styles.thTr }} colSpan="2">G.TOTAL</td>
              <td style={{ ...styles.center, ...styles.td, ...styles.thTr }}>{Math.round(bill.amount + 2 * bill.amount * .09)}</td>
            </tr>
          </tfoot>
        </table>
        <p style={{ textAlign: 'end', paddingRight: '10px', marginTop: '50px' }}>For: UDIT ENTERPRISES</p>
      </div>
    </div>
  );
};

export default GenerateBill;
