import React, { useEffect, useState } from 'react'
import { getAttendence, getLabourHistory, getDepartment, getWorkType } from '../../ContextApi/Api/api';
import toast from 'react-hot-toast';
import { Modal, Button } from 'antd';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


function ShowLabourAttendence() {
  const [data, setData] = useState([])
  const [lastpage, setLastPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const [updt, setUpdate] = useState(false);
  const [hiData, setHidata] = useState([]);
  const [hilastpage, setLastPagehi] = useState(1)
  const [hicurrentPage, setCurrentPagehi] = useState(1);
  const [id, setId] = useState('');


  const [filtDepart, setFiltDepart] = useState('');
  const [filWorkType, setFilWorkType] = useState('');
  const [department, setDepart] = useState([])
  const [worktype, setWorkType] = useState([])

  const fetch = async () => {
    try {
      const res = await getAttendence({ page: currentPage, data: { department: filtDepart, workType: filWorkType } });

      if (res?.data?.data) {
        setCurrentPage(res?.data?.current_page)
        setLastPage(res?.data?.last_page)
        setData(res?.data?.data)
      } else {
        toast.error(res?.response?.data?.message)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }


  const handleCancel = () => {
    setUpdate(!updt)
  }

  const handleSeeHistory = async ({ page, id }) => {
    setUpdate(!updt);
    try {
      const res = await getLabourHistory({ page, id });
      console.log(res?.data);
      if (res?.data?.data) {
        setHidata(res?.data?.data)
        setCurrentPagehi(res?.data?.current_page)
        setLastPagehi(res?.data?.last_page)
      } else {
        toast.error(res?.response?.data?.message)
      }

    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }


  const handlePageChange2 = (event, value) => {
    setCurrentPagehi(value);
  };


  const handlePageChange = (event, value) => {
    handleSeeHistory({ page: value, id })
    setCurrentPage(value);
  };

  const fetchDepart = async () => {
    try {
      const res = await getDepartment();
      // toast.success(res?.data?.message)
      // console.log(res?.data?.data);
      if (res?.data?.data) {
        setDepart(res?.data?.data)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }

  const fetchgetWorkType = async (department_id) => {
    try {
      const res = await getWorkType(department_id);
      // toast.success(res?.data?.message)
      // console.log(res);
      if (res?.data?.data) {
        setWorkType(res?.data?.data)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }

  const filter = (e) => {
    const val = e.target.value.split(',')
    setFilWorkType('')
    setFiltDepart(val[1])
    fetchgetWorkType(val[0])
  }

  useEffect(() => {
    fetchDepart()
  }, [])

  useEffect(() => {
    fetch();
  }, [filtDepart, filWorkType, currentPage]);


  return (
    <div>

      <Modal title="Labour History" open={updt} footer={null} onCancel={handleCancel}>
        <table className="table table-centered table-nowrap">
          <thead>
            <tr>
              <th>Present</th>
              <th>OverTime</th>
              <th>Attendence Time</th>
              <th>Month & Date</th>
            </tr>
          </thead>
          <tbody>

            {
              hiData.map((item, index) => (
                <tr key={index}>
                  <td >{item?.presentOrNot}   &bull; </td>
                  <td>{item?.otH}</td>
                  <td>{item?.att_time}</td>
                  <td>{item?.MonthAndDate}</td>
                </tr>
              ))

            }

          </tbody>

        </table>
        <div className='p-2'>
          <Stack spacing={2}>
            <Pagination count={hilastpage} color="secondary" size="small" onChange={handlePageChange2} page={hicurrentPage} />
          </Stack>
        </div>

      </Modal>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Attendence</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="''">Show attendencd </a></li>
                  <li className="breadcrumb-item active">History Attendence</li>
                </ol>
              </div>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="position-relative" style={{ display: 'flex', gap: '10px' }}>

                  <select name="department_id" id="department_id" className='form-control' style={{ Width: '200px' }}
                    onChange={filter}>
                    <option value='' >Search departement Wise</option>
                    {
                      department.map((item, index) => (
                        <option key={index} value={`${item?._id},${item?.name}`}>{item?.name}</option>
                      ))
                    }
                  </select>

                  <select
                    style={{ Width: '200px' }}
                    id="workType"
                    className="form-control"
                    onChange={e => setFilWorkType(e.target.value)}

                  >
                    <option value="">Select workType</option>
                    {
                      worktype.map((item, index) => (
                        <option value={item?.name} key={index}>{item?.name}</option>
                      ))

                    }
                  </select>

                  <Button onClick={() => {
                    setFilWorkType('')
                    setFiltDepart('')
                  }}>
                    All
                  </Button>


                </div>

                <div className="row mb-6">
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap">
                      <thead>
                        <tr>
                          <th>Department Name</th>
                          <th>Work type</th>
                          <th>Labour name</th>
                          <th>Present</th>
                          <th>OverTime</th>
                          <th>SHOW History</th>

                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.map((item, index) => (
                            <tr key={index}>
                              <td >{item?.department}</td>
                              <td>{item?.workType}</td>
                              <td>{item?.labour}</td>
                              <td style={{ backgroundColor: item?.presentOrNot === 'Yes' ? 'green' : 'red', textAlign: 'center', color: 'white', fontWeight: 800, fontSize: '30px' }}>
                                {item?.presentOrNot}
                              </td>

                              <td>{item?.otH}</td>
                              <td>
                                <Button onClick={() => {
                                  setId(item?.labour_id);
                                  handleSeeHistory({ page: 1, id: item?.labour_id });
                                }}>See</Button>
                              </td>
                            </tr>
                          ))

                        }
                      </tbody>
                    </table>
                    <div className='p-2'>
                      <Stack spacing={2}>
                        <Pagination count={lastpage} color="secondary" size="small" onChange={handlePageChange} page={currentPage} />
                      </Stack>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default ShowLabourAttendence