import React, { useEffect, useState } from 'react';
import { getDepartment, createWorkType } from '../../ContextApi/Api/api'
import toast from 'react-hot-toast';
function CreateDepartmentRate() {
  const [formData, setFormData] = useState({
    department_id: '',
    name: '',
    rate: ''
  });

  const [data, setData] = useState([])
  // Function to handle input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.department_id.trim() === "" ||
      formData.name.trim() === "" ||
      formData.rate.trim() === ""
    ) {
      // If any field is empty, show an error message
      toast.error("Please fill in all fields");
      return;
    }
    try {
      const res = await createWorkType(formData);

      if (res?.data?.success) {
        setFormData({
          ...formData,
          name: '',
          rate: ''
        })
        toast.success(res?.data?.message)
      }

    } catch (error) {
      console.log(error);
      toast.error('Try again !')
    }

  };

  const fetchDepartments = async () => {
    try {
      const res = await getDepartment();
      if (res?.data?.data) {
        setData(res?.data?.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchDepartments()
  }, [])

  return (
    <div>
      <div className="card-body">
        <h4 className="card-title mb-4">Department</h4>

        <form>

          <div className="row">

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="department_id">Department Name</label>

                <select name="department_id" id="department_id" className='form-control'
                  onChange={handleInputChange}>
                  <option >select departement</option>
                  {
                    data.map((item, index) => (
                      <option key={index} value={item?._id}>{item?.name}</option>
                    ))
                  }
                </select>

              </div>
            </div>


            {
              formData?.department_id && (
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="departmentname">Work Type</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              )

            }

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="rateNumber">Rate</label>
                <input
                  type="text"
                  className="form-control"
                  id="rate"
                  value={formData.rate}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <button onClick={handleSubmit} className="btn btn-primary w-md">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default CreateDepartmentRate;
