import React from "react";
const LeftSection = () => {
  return (
    <div className="bg-auth bg-login-img">
      {/* <h1>SERVICES</h1>
      
      <ul>
        <li>Mobile App Development</li>
        <li> Website Development </li>
        <li> Game Development </li>
        <li> Degital Marketing & SEO </li>
        <li> Hosting & Server  Solution  </li>
      </ul> */}
     
    </div>
  );
};

export default LeftSection;
