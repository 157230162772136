import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { createPayment } from '../../ContextApi/Api/bills_api'
import { getDepartment, getWorkType, getfilterLabourWorktype } from '../../ContextApi/Api/api';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



function PaymentHistory() {
    const [department, setDepart] = useState([]);
    const [worktype, setWorkType] = useState([]);
    const [labour, setLabour] = useState([]);
    const [formData, setFormData] = useState({
        department: "",
        labour: "",
        workType: "",
        labour_id: "",
        amountType: "",
        enterAmount: "",
        tillDateAmount: "",
        tillDateRemainingAmount: "",
        whyTakeAmount: ""
    });

    const fetch = async () => {
        try {
            const res = await getDepartment();
            if (res?.data?.data) {
                setDepart(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }

    const fetchgetLabourWorkType = async (data) => {
        try {
            const res = await getfilterLabourWorktype(data);
            if (res?.data?.data) {
                setLabour(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }

    const fetchgetWorkType = async (department_id) => {
        try {
            const res = await getWorkType(department_id);
            if (res?.data?.data) {
                setWorkType(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }

    const handleDepartment = (e) => {
        const { id, value } = e.target;
        const valu = value.split(",");
        const _id = valu[0];
        const name = valu[1];
        console.log(valu[1]);
        setFormData((prev) => ({
            ...prev,
            [id]: name
        }));
        fetchgetWorkType(_id)
    }

    const handleWorkType = (e) => {
        const { id, value } = e.target;
        fetchgetLabourWorkType(value)
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    }

    const handleLabour = (e) => {
        const { id, value } = e.target;
        const valu = value.split(",");
        const labour_id = valu[0];
        const name = valu[1];
        setFormData(prev => ({
            ...prev,
            [id]: name,
            labour_id
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (
                !formData.amountType ||
                !formData.workType ||
                !formData.department ||
                !formData.labour ||
                !formData.labour_id ||
                !formData.enterAmount ||
                !formData.tillDateAmount ||
                !formData.tillDateRemainingAmount ||
                !formData.whyTakeAmount
            ) {
                toast.error('Please fill all required fields!');
                return;
            }


            const res = await createPayment(formData);

            if (res?.data?.success) {
                toast.success(res?.data?.message);
                setFormData({
                    departmentType: "",
                    labourName: "",
                    amountType: "",
                    enterAmount: "",
                    tillDateAmount: "",
                    tillDateRemainingAmount: "",
                    whyTakeAmount: ""
                });
            }

        } catch (error) {
            console.log(error);
            toast.error('Failed to submit payment history!');
        }
    }

    useEffect(() => {
        fetch();
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-2 font-size-18">Payment History</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="''">BASIC DETAILS</a></li>
                                <li className="breadcrumb-item"> &gt; </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Form Fill</h4>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="department">Department</label>
                                            <select id="department"
                                                className="form-control"
                                                onChange={handleDepartment}>
                                                <option value="">Select Department</option>
                                                {department.map((item, index) => (
                                                    <option key={index} value={`${item._id},${item.name}`}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {worktype.length !== 0 && (
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="workType">Work Type</label>
                                                <select
                                                    id="workType"
                                                    className="form-control"
                                                    value={formData?.workType}
                                                    onChange={handleWorkType}
                                                >
                                                    <option value="">Select Work Type</option>
                                                    {worktype.map((item, index) => (
                                                        <option key={index} value={item.name}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {labour.length !== 0 && (
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="labour">Labour Name</label>
                                                <select
                                                    id="labour"
                                                    className="form-control"
                                                    onChange={handleLabour}
                                                    value={formData?.labour}
                                                >
                                                    <option value="">Select Labour</option>
                                                    {labour.map((item, index) => (
                                                        <option key={index} value={`${item._id},${item.name}`}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="amountType">Amount type</label>
                                            <select
                                                id="amountType"
                                                className="form-control"
                                                value={formData.amountType}
                                                onChange={(e) => setFormData({ ...formData, amountType: e.target.value })}
                                            >
                                                <option value="Advance">Advance</option>
                                                <option value="Salary">Salary</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="enterAmount">Enter Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="enterAmount"
                                                value={formData.enterAmount}
                                                onChange={(e) => setFormData({ ...formData, enterAmount: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="tillDateAmount">Till date Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="tillDateAmount"
                                                value={formData.tillDateAmount}
                                                onChange={(e) => setFormData({ ...formData, tillDateAmount: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="tillDateRemainingAmount">Till date Remaining Amount</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="tillDateRemainingAmount"
                                                value={formData.tillDateRemainingAmount}
                                                onChange={(e) => setFormData({ ...formData, tillDateRemainingAmount: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="whyTakeAmount">Why take Amount</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={formData.whyTakeAmount}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFormData({ ...formData, whyTakeAmount: data });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="btn btn-primary w-md">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentHistory;
