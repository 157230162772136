import React, { useEffect, useState } from 'react';
import { createBills } from '../../ContextApi/Api/bills_api'
import { getDepartment } from '../../ContextApi/Api/api';
import toast from 'react-hot-toast';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CreateBills() {
    const [formData, setFormData] = useState({
        invoiceNumber: '',
        poNumber: '',
        startDate: '',
        endDate: '',
        billDate: '',
        departmentType: '',
        date: '',
        quantity: '',
        unit: '',
        rate: '',
        poDescription: ''
    });
    const [error, setError] = useState('');
    const [department, setDepart] = useState([])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                formErrors[key] = 'This field is required';
            }
        });

        try {
            const res = await createBills(formData);

            console.log(res?.data?.data);
            if (res?.data?.message) {
                toast.success(res?.data?.message);
                setFormData({
                    invoiceNumber: '',
                    poNumber: '',
                    startDate: '',
                    endDate: '',
                    billDate: '',
                    departmentType: '',
                    date: '',
                    quantity: '',
                    unit: '',
                    rate: '',

                    poDescription: ''
                })
            }

        } catch (error) {
            toast.error('Network error!')
            console.log(error);
        }
    };


    const fetch = async () => {
        try {
            const res = await getDepartment();
            // toast.success(res?.data?.message)
            console.log(res?.data?.data);
            if (res?.data?.data) {
                setDepart(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData({ ...formData, poDescription: data });
    };

    useEffect(() => {
        fetch()
    }, [])

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title mb-4">Form Fill</h4>

                {error && <div className="alert alert-danger">{error}</div>}
                <form onSubmit={handleSubmit}>

                    <div className="row">
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="invoiceNumber">INVOICE NUMBER</label>
                                <input type="text" className="form-control" id="invoiceNumber" name="invoiceNumber" value={formData.invoiceNumber} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="poNumber">PO NUMBER</label>
                                <input type="text" className="form-control" id="poNumber" name="poNumber" value={formData.poNumber} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="startDate">START DATE</label>
                                <input type="date" className="form-control" id="startDate" name="startDate" value={formData.startDate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="endDate">END DATE</label>
                                <input type="date" className="form-control" id="endDate" name="endDate" value={formData.endDate} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="billDate">BILL DATE</label>
                                <input type="date" className="form-control" id="billDate" name="billDate" value={formData.billDate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="departmentType">Department </label>
                                <select className="form-control" id="departmentType" name="departmentType" value={formData.departmentType} onChange={handleChange}>
                                    <option value="">Select Department</option>
                                    {department.map((item, index) => (
                                        <option key={index} value={`${item._id},${item.name}`} >{item?.name}</option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="date">Date</label>
                                <input type="date" className="form-control" id="date" name="date" value={formData.date} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="unit">UNIT</label>
                                <input type="text" className="form-control" id="unit" name="unit" value={formData.unit} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="quantity">Quantity</label>
                                <input type="number" className="form-control" id="quantity" name="quantity" value={formData.quantity} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-group">
                                <label htmlFor="rate">RATE</label>
                                <input type="number" className="form-control" id="rate" name="rate" value={formData.rate} onChange={handleChange} />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label htmlFor="poDescription">PO Description</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.poDescription}
                                    onChange={handleEditorChange}
                                />

                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary w-md">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateBills;
