// ShowPaymentHistory.js

import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getPayments } from '../../ContextApi/Api/bills_api'
import toast from 'react-hot-toast'
import { useState } from 'react';
import parse from 'html-react-parser';

function ShowPaymentHistory() {

    const [data, setData] = useState([])
    const [lastpage, setLastPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);

    const fetch = async () => {
        try {
            const res = await getPayments({ page: currentPage });
            console.log(res?.data?.data);
            if (res?.data?.data) {
                setData(res?.data?.data)
                setCurrentPage(res?.data?.current_page)
                setLastPage(res?.data?.last_page)
            } else {
                toast.error(res?.response?.data?.message)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }



    useEffect(() => {
        fetch()
    }, [currentPage])


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };





    return (
        <div className="container-fluid">
            {/* start page title */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Show Payment  Details</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="''">Payment </a></li>
                                <li className="breadcrumb-item active">Show Payment details </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {/* end page title */}

            <div className="row">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap">
                            <thead>
                                <tr>
                                    <th>Department Type</th>
                                    <th>Labour Name</th>
                                    <th>Amount type</th>
                                    <th>Work Type</th>
                                    <th>Till date Amount</th>
                                    <th>Why taken Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((item, i) => (
                                        < tr key={i} >
                                            <td>{item?.department}</td>
                                            <td>{item?.labour}</td>
                                            <td>{item?.amountType}  </td>
                                            <td>{item?.workType}</td>
                                            <td>{item?.tillDateAmount}</td>
                                            <td>{parse(item?.whyTakeAmount)}  </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>


                        <div className='p-2'>
                            <Stack spacing={2}>
                                <Pagination count={lastpage} color="secondary" size="small" onChange={handlePageChange} page={currentPage} />
                            </Stack>
                        </div>
                    </div>

                </div>
            </div>
            {/* end row */}
        </div >
    );
}

export default ShowPaymentHistory;
